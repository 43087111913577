import React from 'react';
import { ModalProvider } from 'styled-react-modal';
import { BrowserRouter } from 'react-router-dom';

import './Style/GlobalStyles.css';
import { ModalBackground } from './SubComponents/Modals';
import * as Tooltip from '@radix-ui/react-tooltip';
import { starboard } from './Style/themes/starboard';
import { ThemeProvider } from 'styled-components/macro';

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers = (props: ProvidersProps) => {
  return (
    <ThemeProvider theme={starboard}>
      <ModalProvider backgroundComponent={ModalBackground}>
        <BrowserRouter>
          <Tooltip.Provider>{props.children}</Tooltip.Provider>
        </BrowserRouter>
      </ModalProvider>
    </ThemeProvider>
  );
};

export default Providers;
