/* eslint-disable max-lines */
import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ------------- FRENCH ------------
 *| |_______________________________
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| ||          MMMMMMMMMM,,,,,,,,,,|
 *| |'""""""""""""""""""""""""""""""'
 */
export const fr: SingleLanguageLocalization = {
  /**
   * Product
   */
  product: {
    location: 'Emplacement',
    noLocationSelected: 'Aucun emplacement choisi',
    invalidBookingId: 'Réservation introuvable - ID de réservation invalide',
    confirm: 'Confirmer',
    Booking: 'Réservation',
    booking: 'réservation',
    yourBooking: 'Votre réservation',
    Giftcard: 'Carte cadeau',
    giftcard: 'carte cadeau',
    campaign: 'campagne',
    voucher: 'bon',
    refund: 'Remboursement',
    membership: 'adhésion',
    'membership-campaign': 'campagne',
    yourGiftcard: 'Votre carte cadeau',
    configHeader: 'Bateaux & durée',
    durationHeading: 'Bateaux & durée',
    dateAndTime: 'Date & heure de départ',
    yourInformation: 'Vos informations',
    extras: 'Extras',
    extrasAndRules: 'Extras & Règles',
    finalize: 'Finaliser la commande',
    completeBooking: `Votre réservation est presque confirmée.

        Finalisez votre réservation en cliquant sur le bouton à droite.`,
    completeBookingMobile: `Votre réservation est presque confirmée.

        Finalisez votre réservation via l'aperçu de commande en bas de l'écran.`,
    numberOfUnits: 'Nombre de bateaux',
    personsPerUnit: '8 pers. par bateau',
    boat: 'bateau',
    boats: 'bateaux',
    hour: 'heure',
    hours: 'heures',
    minutes: 'minutes',
    hourAbbr: 'h',
    minuteAbbr: 'm',
    chooseLocation: 'Choisir un emplacement',
    currentBooking: 'Réservation actuelle',
    show: 'Afficher',
    hide: 'Masquer',
    currentbooking: 'réservation actuelle',
    currentgiftcard: 'carte cadeau actuelle',
    configureGiftcard: 'Configurez votre carte cadeau',
    durationInHours: 'Durée',
    acceptTerms: 'Accepter les conditions générales',
    subscribeToNewsletter:
      'Je souhaite recevoir la newsletter de GoBoat avec inspiration maritime et informations sur les événements',
    yes: 'Oui',
    no: 'Non',
    subscribeToNewsletterShort: 'Inscrivez-vous à la newsletter',
    next: 'Suivant',
    prev: 'Précédent',
    page: 'Page',
    addToOrder: 'Ajouter à la commande',
    added: 'Ajouté',
    haveAVoucher: 'Utiliser un bon',
    voucherCode: 'Code du bon',
    applyVoucher: 'Appliquer le bon',
    voucherApplied: 'Un bon a été appliqué',
    toBePaid: 'À payer',
    confirmPayment: 'Confirmer le paiement',
    confirmBooking: 'Confirmer la réservation',
    confirmGiftcard: 'Confirmer la carte cadeau',
    confirmMembership: "Confirmer l'adhésion",
    cancelBooking: 'Annuler la réservation',
    cancelTextVoucherRefund1: `Êtes-vous sûr de vouloir annuler votre réservation?

Vous recevrez un e-mail avec un bon d'une valeur de `,
    cancelTextVoucherRefund2: `
Le bon expire dans `,
    cancelTextVoucherRefund3: ` mois.`,
    cancelTextRefund1: `
Si vous souhaitez un remboursement, vous devez contacter le service client.`,
    cancelTextRefund2: `
Comme le temps de départ est inférieur à `,
    cancelTextRefund3: ` heures, vous ne pouvez pas recevoir de remboursement.`,
    cancelTextNoRefund1: `Êtes-vous sûr de vouloir annuler votre réservation?

Comme le temps de départ est inférieur à `,
    cancelTextNoRefund2: ` heures ou aucun paiement n'a été reçu pour la réservation, votre réservation sera annulée et vous ne recevrez aucun remboursement.`,
    canceledBooking: 'Nous sommes désolés de vous voir partir!',
    canceledTextVoucherRefund: `Un bon a été émis et vous sera envoyé par e-mail sous peu.

Nous comprenons que les plans peuvent changer, et nous aimerions savoir pourquoi vous avez dû annuler votre réservation chez nous. Vos commentaires sont inestimables et nous aident à améliorer nos services.`,

    canceledTextNoRefund:
      'Nous comprenons que les plans peuvent changer, et nous aimerions savoir pourquoi vous avez dû annuler votre réservation chez nous. Vos commentaires sont inestimables et nous aident à améliorer nos services.',

    cancellationFeedbackPrompt:
      "Veuillez nous faire savoir la raison de votre annulation en cliquant sur l'une des options ci-dessous :",
    cancellationThankYou:
      "Merci pour vos commentaires. Nous apprécions votre temps et espérons vous servir mieux à l'avenir.",
    canceledTextInitial:
      'La réservation est annulée, cliquez sur le bouton ci-dessous si vous souhaitez réserver une nouvelle expérience.',
    canceledButtonTextInitial: 'Réserver une nouvelle expérience',
    pleaseWait: 'Veuillez patienter...',
    positiveBalance1: 'Votre réservation a un solde positif.',
    positiveBalance2Refund:
      'Vous pouvez utiliser ce solde pour acheter des extras, ou vous pouvez demander un remboursement en contactant le service client.',
    positiveBalance2VoucherRefund:
      'Vous pouvez utiliser ce solde pour acheter des extras, ou vous pouvez demander un bon en contactant le service client.',
    positiveBalance3:
      "Vous pouvez également demander le remboursement à l'avance en envoyant un e-mail à ",
    neutralBalance:
      'Votre commande est équilibrée - aucun paiement supplémentaire requis.',
    refundEmailSubject: 'Remboursement - ID de réservation : ',
    priceForSailing: 'Prix pour la navigation',
    priceForExtras: 'Prix pour les extras',
    priceForBooking: 'Prix pour la réservation',
    cancellationWarranty: "Garantie d'annulation",
    cancellationWarrantyHeading: "Vous n'êtes pas sûr de pouvoir y arriver ?",
    cancellationWarrantyText1:
      "En ajoutant notre garantie d'annulation, vous pourrez reporter ou annuler votre réservation jusqu'à ",
    cancellationWarrantyText2: ' heures avant le départ.',
    thankYou: 'Merci',
    captain: 'capitaine',
    bookingCompleted:
      'Votre réservation est terminée. Nous avons hâte de vous voir au quai.',
    practicalInfo1: "Veuillez vous assurer d'être à la base de location ",
    practicalInfo2: " au moins 15 minutes avant l'heure de départ.",
    confirmationEmailSentTo: 'Un e-mail de confirmation a été envoyé à',
    learnMore: 'En savoir plus',
    cancel: 'Annuler',
    fetching: 'Chargement',
    loading: 'Chargement',
    selectDateToViewTimeslots:
      'Sélectionnez une date pour obtenir les créneaux horaires disponibles',
    yourInformationBookingHeld:
      'Une fois que vous avez rempli vos informations, votre heure de départ sera réservée pendant 10 minutes.',
    noOpenDays: "Il n'y a pas de jours ouverts dans le mois sélectionné",
    defaultPaymentSuccessText: `Merci pour votre réservation, nous avons hâte de vous accueillir à bord !

Veuillez vous rappeler d'arriver 15 minutes avant l'heure de départ.

Si vous avez des questions avant votre navigation, veuillez contacter notre équipe.`,
    OR: 'OU',
    left: 'gauche',
    goToCheckout: 'Aller à la caisse',
    bookingHeldHeading: 'Votre heure de départ est réservée',
    bookingHeldText:
      'Après 10 minutes, nous ne pouvons plus garantir que votre heure de départ est toujours disponible.',
    allRightsReserved: 'Tous droits réservés',
    cookieSettings: 'Paramètres des cookies',
    custom: 'Personnalisé',
    amount: 'Montant',
    customAmount: 'Montant personnalisé',
    selectPickUpLocation: "Choisissez votre lieu d'embarquement",
    selectDropOffLocation: 'Choisissez votre lieu de débarquement',
    select: 'Sélectionner',
    selected: 'Sélectionné',
    includedInPrice: 'Inclus dans le prix',
    viewOnMap: 'Voir sur la carte',
    pickupLocation: "Lieu d'embarquement",
    dropoffLocation: 'Lieu de débarquement',
    from: 'De',
  },

  /**
   * Giftcard
   */
  giftcard: {
    recipientInformation: 'Informations sur le destinataire',
    optional: 'Facultatif',
    defaultPaymentSuccessText:
      "Merci pour votre achat d'une carte cadeau pour une expérience unique et durable chez GoBoat.",
  },

  /**
   * Membership
   */
  membership: {
    membership: 'adhésion',
    yourMembership: 'Votre adhésion',
    defaultPaymentSuccessText: "Merci de vous être inscrit à l'adhésion GoBoat Plus",
  },

  /**
   * Sauna
   */
  sauna: {
    duration: 'Durée',
    dateAndTime: 'Date et heure',
    selectDateToViewTimeslots:
      'Sélectionnez une date pour obtenir les créneaux horaires disponibles',
    defaultPaymentSuccessText: `Merci pour la réservation. Nous avons hâte de vous accueillir.

Veuillez vous présenter 15 minutes avant votre créneau horaire.

Si vous avez des questions concernant votre réservation, n'hésitez pas à nous contacter.`,
    boat: 'personne',
    boats: 'personnes',
    numberOfUnits: 'Nombre de personnes',
    personsPerUnit: 'Capacité : 8 personnes',
    durationHeading: 'Personnes & durée',
  },

  /**
   * Customer
   */
  customer: {
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    phone: 'Numéro de téléphone',
    email: 'E-mail',
    birthDate: 'Date de naissance',
    birthDatePattern: '(AAAA-MM-JJ)',
    zip: 'Code Postal',
    province: 'Province',
    streetAddress: 'Adresse',
    city: 'Ville',
    pin: 'Italian Fiscal Code',
  },

  /**
   * Recipient
   */
  recipient: {
    name: 'Nom',
  },

  /**
   * Price
   */
  price: {
    price: 'Prix',
    allIncluded: 'Prix tout compris',
    paid: 'Payé',
    toBePaid: 'À payer',
    toBeRefunded: 'À rembourser',
    confirm: 'Confirmer',
  },

  /**
   * Lookup
   */
  lookup: {
    noBookingTitle: 'Hmm, nous ne trouvons pas cette réservation...',
    noBookingContent:
      "Il semble que nous n'ayons aucune réservation dans le système avec l'ID :",
    noRequestTitle: 'À la recherche de votre réservation ?',
    noRequestContent: "Il semble que vous n'ayez demandé aucune réservation du tout.",
    giefId:
      "Veuillez fournir l'ID de réservation de l'e-mail de confirmation que vous avez reçu de notre part.",
    getBooking: 'Obtenir une réservation',
  },

  /**
   * Error
   */
  error: {
    NoShopId: "L'ID de la boutique n'est pas valide.",
    NoBoatCount: 'La réservation ne contient aucun bateau.',
    NoAvailableTimeslots: 'Aucun créneau horaire disponible.',
    SlotNoLongerAvailable:
      "Le créneau horaire sélectionné n'est plus disponible. Veuillez sélectionner un autre créneau horaire.",
    BookingNotCreated:
      "Une erreur s'est produite. La réservation n'a pas été créée. Veuillez rafraîchir la page et réessayer.",
    SelectStartTime: 'Veuillez sélectionner une heure de début pour votre réservation.',
    WeekdayNotValid: "Le jour de la semaine sélectionné n'est pas éligible pour ce bon.",
    VoucherAlreadyAdded: 'Un bon a déjà été ajouté à cette réservation.',
    VoucherNotValidForTimeslot:
      "Le bon n'est pas valide à l'heure de départ sélectionnée.",
    PercentVoucherAlreadyUsed: 'Un bon a déjà été ajouté à cette réservation.',
    VoucherDepleted: "Le bon n'est plus valide.",
    VoucherLocationInvalid: "Le bon n'est pas valide pour l'emplacement sélectionné.",
    VoucherWeekdayInvalid:
      "Le bon n'est pas valide pour le jour de la semaine sélectionné.",
    VoucherDateInvalid: "Le bon n'est pas valide pour la date sélectionnée.",
    VoucherValidForInvalid: "Le bon n'est pas valide.",
    VoucherInvalid: "Le bon n'est pas valide.",
    InvalidVoucherCombination: 'Ces bons ne peuvent pas être utilisés ensemble.',
    VoucherAlreadyApplied: 'Le bon a déjà été appliqué à la réservation.',
    NotValidPayment:
      "La demande de paiement n'est pas valide - la transaction n'a pas été effectuée.",
    ShopNotOpen: "La boutique n'est pas ouverte à la date sélectionnée.",
    ShopNoLongerOpen:
      "La boutique n'a plus d'heures d'ouverture valides à la date sélectionnée.",
    ShopHasNoPriceModels: "La boutique n'a pas de modèles de prix.",
    NoShopsFound: 'Aucun emplacement trouvé',
    UnknownError:
      "Une erreur inconnue s'est produite. Veuillez rafraîchir la page et réessayer.",
    PriceMismatchError:
      'Le prix pour votre créneau horaire sélectionné a changé. Veuillez rafraîchir la page et réessayer.',
    NotAvailable: 'Non disponible',
    DepartureTimesNotAvailable:
      'Ces heures de départ ne sont pas disponibles avec la durée sélectionnée :',
    NotAvailableForOnlineBooking: 'Non disponible pour les réservations en ligne',
    PersonalDetailsInvalid: 'Les détails personnels ne sont pas valides.',
    Alcoholerror: "La quantité d'alcool dépasse la limite autorisée.",
    WarrantyWasRemoved1:
      "La garantie d'annulation a été supprimée parce que l'heure de départ est dans moins de",
    WarrantyWasRemoved2: 'heures.',
    WarrantyCannotBeAdded1:
      "La garantie d'annulation ne peut pas être ajoutée si l'heure de départ est dans moins de",
    WarrantyCannotBeAdded2: 'heures.',
    WarrantyMissingDateAndTime:
      "Veuillez sélectionner une date et une heure de départ avant d'ajouter la garantie d'annulation.",
    WarrantyInvalidDateAndTime:
      "La garantie d'annulation ne peut pas être ajoutée à la date et à l'heure de départ choisies.",
  },
  faq: {
    title: 'Comment acheter un certificat cadeau',
    p1: "La carte cadeau et le code unique seront envoyés dans votre boîte de réception avec des instructions sur son utilisation. Veuillez conserver cet e-mail en sécurité car il constitue votre preuve d'achat. La carte cadeau peut être utilisée pour la valeur monétaire de votre achat aujourd'hui dans un délai de",
    p2: "mois pour la location de bateau à l'emplacement que vous avez sélectionné. Elle ne peut pas être échangée contre de l'argent.",
    campaign:
      "La carte cadeau et le code unique seront envoyés dans votre boîte de réception avec des instructions sur son utilisation. Veuillez conserver cet e-mail en sécurité car il constitue votre preuve d'achat. La carte cadeau peut être échangée pour la valeur monétaire de votre achat aujourd'hui pour la location de bateau à l'emplacement que vous avez sélectionné. Veuillez consulter la date d'expiration sur la carte cadeau. Elle ne peut pas être échangée contre de l'argent.",
    show: 'Afficher FAQ',
    hide: 'Masquer FAQ',
  },
  factBox: {
    close: 'Fermer',
    seeMore: 'Voir plus',
  },
  meta: {
    description:
      "Louez un bateau chez GoBoat et vivez une expérience unique sur l'eau. Les bateaux peuvent transporter jusqu'à huit personnes, et ni permis ni expérience ne sont nécessaires pour faire fonctionner le bateau.",
  },
  faqList: [
    'Choisissez la durée de la location pour la carte cadeau',
    "Saisissez votre adresse e-mail (il s'agit de votre adresse e-mail, pas celle de la personne à qui vous donnerez la carte)",
    'Saisissez vos coordonnées de carte de crédit et confirmez',
  ],

  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Météo',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Changement de plans',
    [CustomerFacingCancellationReason.SICKNESS]: 'Maladie',
    [CustomerFacingCancellationReason.OTHER]: 'Autre',
  },
};
