import { en } from './en';
import { da } from './da';
import { sv } from './sv';
import { de } from './de';
import { es } from './es';
import { it } from './it';
import { fr } from './fr';
import { SupportedLanguage } from '../Utils/graphql-sdk';
import { SingleLanguageLocalization } from './types';

const localizations: Record<SupportedLanguage, SingleLanguageLocalization> = {
  en,
  da,
  sv,
  de,
  es,
  it,
  fr,
};

export default localizations;
