/* eslint-disable max-lines */

import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ---------- SWEDISH --------------
 *| |_______________________________
 *| ||        $$$                   |
 *| ||        $$$                   |
 *| ||        $$$                   |
 *| ||$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$|
 *| ||$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$|
 *| ||        $$$                   |
 *| ||        $$$                   |
 *| ||        $$$                   |
 *| |'""""""""""""""""""""""""""""""'
 */
export const sv: SingleLanguageLocalization = {
  /**
   * Product
   */
  product: {
    location: 'Plats',
    noLocationSelected: 'Ingen plats vald',
    invalidBookingId: 'bokning inte hittad - ogiltigt boknings-ID',
    confirm: 'Bekräfta',
    booking: 'Bokning',
    Booking: 'Bokning',
    yourBooking: 'Din bokning',
    Giftcard: 'Gavekort',
    giftcard: 'gavekort',
    campaign: 'kampanj',
    voucher: 'voucher',
    refund: 'Voucher',
    membership: 'medlemsskap',
    'membership-campaign': 'kampanj',
    yourGiftcard: 'Dit gavekort',
    boat: 'båt',
    boats: 'båtar',
    hour: 'timme',
    hours: 'timmar',
    minutes: 'minuter',
    hourAbbr: 'h',
    minuteAbbr: 'm',
    chooseLocation: 'Välj plats',
    currentBooking: 'Nuvarande bokning',
    show: 'Visa',
    hide: 'Skyla',
    currentbooking: 'nuvarande bokning',
    currentgiftcard: 'nuvarande gavekort',
    configureGiftcard: 'Konfigurera presentkort',
    durationInHours: 'Bokningens längd',
    configHeader: 'Antal båtar & bokningens längd',
    durationHeading: 'Antal båtar & bokningens längd',
    dateAndTime: 'Datum & avgångstid',
    yourInformation: 'Din information',
    extras: 'Tillval',
    extrasAndRules: 'Tillval & Regler',
    finalize: 'Slutföra beställning',
    completeBooking: `Din bokning är reserverad.

        Färdiggør din bestilling genom knappen till höger.`,
    completeBookingMobile: `Din bokning är reserverad.

        Färdiggør din bestilling genom ordens sammanfattning längst ner på skärmen.`,
    numberOfUnits: 'Antal båtar',
    personsPerUnit: '8 pers. per båt',
    acceptTerms: 'Acceptera villkor och betingelser',
    subscribeToNewsletter:
      'Jag skulle vilja få GoBoats nyhetsbrev med maritim inspiration och information om evenemang',
    yes: 'Ja',
    no: 'Nej',
    subscribeToNewsletterShort: 'registrering för nyhetsbrev',
    next: 'Nästa',
    prev: 'Föregående',
    page: 'Sida',
    addToOrder: 'Lägg till beställning',
    added: 'Tillagd',
    haveAVoucher: 'Använd en rabattkod',
    voucherCode: 'Rabattkod',
    applyVoucher: 'Använd rabattkod',
    voucherApplied: 'Rabattkoden har blivit tillagd',
    toBePaid: 'Belopp för betalning',
    confirmPayment: 'Bekräfta betalning',
    confirmBooking: 'Bekräfta bokning',
    confirmGiftcard: 'Bekräfta presentkort',
    confirmMembership: 'Bekräfta medlemsskap',
    cancelBooking: 'Avboka',
    cancelTextVoucherRefund1: `Er du sikker på at du vil annullere din booking?

Du vil modtage en email med en voucher med en værdi på `,
    cancelTextVoucherRefund2: `
Voucheren udløber om `,
    cancelTextVoucherRefund3: ` måneder.`,
    cancelTextRefund1: `
Hvis du ønsker en refunderering, skal du kontakte kundeservice.`,
    cancelTextRefund2: `
Fordi udsejlingen finder sted om mindre end `,
    cancelTextRefund3: ` timer, har du ikke mulighed for at modtage en refundering.`,
    cancelTextNoRefund1: `Er du sikker på at du vil annullere din booking?

Fordi udsejlingen finder sted om mindre end `,
    cancelTextNoRefund2: ` timer eller ingen betaling for bookingen er modtaget, vil din booking blive annulleret og du vil ikke modtage en refundering.`,
    canceledBooking: 'Vi är ledsna att se dig gå!',
    canceledTextVoucherRefund: `Ett värdebevis har utfärdats och kommer att skickas till dig via e-post inom kort.

Vi förstår att planer kan förändras, och vi skulle gärna vilja veta varför du var tvungen att avboka din bokning hos oss. Din feedback är ovärderlig och hjälper oss att förbättra våra tjänster.`,
    canceledTextNoRefund:
      'Vi förstår att planer kan förändras, och vi skulle gärna vilja veta varför du var tvungen att avboka din bokning hos oss. Din feedback är ovärderlig och hjälper oss att förbättra våra tjänster.',

    cancellationFeedbackPrompt:
      'Vänligen meddela oss anledningen till din avbokning genom att klicka på ett av alternativen nedan:',
    cancellationThankYou:
      'Tack för din feedback. Vi uppskattar din tid och hoppas kunna ge dig bättre service i framtiden.',
    canceledTextInitial:
      'Bookingen er annulleret, klik på knappen herunder hvis du ønsker at booke en ny oplevelse.',
    canceledButtonTextInitial: 'Book ny oplevelse',
    pleaseWait: 'Snälla vänta...',
    positiveBalance1:
      'Du har pengar tillgodo på din bokning som kan användas för tillval.',
    positiveBalance2Refund:
      'Du kan också få beloppet tillbaka genom att kontakta kundtjänst.',
    positiveBalance2VoucherRefund:
      'Du kan också få beloppet som ett tillgodokvitto genom att kontakta kundtjänst.',
    positiveBalance3:
      'Du kan också begära en återbetalning i förväg genom att skicka en e-postmeddelande till ',
    neutralBalance: 'Din beställning är balanserad - ingen betalning behövs.',
    refundEmailSubject: 'Återbetaling - booking ID: ',
    priceForSailing: 'Pris for segling',
    priceForExtras: 'Pris for tilvalg',
    priceForBooking: 'Pris for booking',
    cancellationWarranty: 'Afbestillingsgaranti',
    cancellationWarrantyHeading: 'Har du brug for ekstra sikkerhed?',
    cancellationWarrantyText1:
      'Ved at tilvælge vores afbestillingsgaranti, har du mulighed for at ombooke eller annullere din booking op til ',
    cancellationWarrantyText2: ' timer før udsejling.',
    thankYou: 'Tack',
    captain: 'kapten',
    bookingCompleted: 'Din bokning är slutförd. Vi ser fram emot att se dig på hamnen.',
    practicalInfo1: 'Kom ihåg att ankomma til uthyrningen ',
    practicalInfo2: ' minst 15 minuter innan turen börjar.',
    confirmationEmailSentTo: 'Ett bekräftelsemeddelande har skickats till',
    learnMore: 'Läs mer',
    cancel: 'Avboka',
    fetching: 'Hämtar',
    loading: 'Behandlar',
    selectDateToViewTimeslots: 'Välj ett datum för att se tillgängliga avgångstider',
    yourInformationBookingHeld:
      'När du har fyllt i dina uppgifter kommer din avgångstid att reserveras i 10 minuter.',
    noOpenDays: 'Det finns inga öppna dagar i den valda månaden',
    defaultPaymentSuccessText: `Din booking er gennemført. Vi ser frem til at se dig på pontonen.

Husk at ankomme på pontonen mindst 15 min. før sejladsen begynder.`,
    OR: 'ELLER',
    left: 'kvar',
    goToCheckout: 'Gå till kassan',
    bookingHeldHeading: 'Din avgångstid är reserverad',
    bookingHeldText:
      'Efter 10 minuter kan vi inte garantera att din avgångstid fortfarande är tillgänglig.',
    allRightsReserved: 'Alla rättigheter förbehålls',
    cookieSettings: 'Cookieinställningar',
    custom: 'Custom',
    amount: 'Amount',
    customAmount: 'Custom amount',
    selectPickUpLocation: 'Välj var du vill gå ombord',
    selectDropOffLocation: 'Välj var du vill gå iland',
    select: 'Välj',
    selected: 'Vald',
    includedInPrice: 'Ingår i priset',
    viewOnMap: 'Se på karta',
    pickupLocation: 'Upphämtning',
    dropoffLocation: 'Avlämning',
    from: 'Från',
  },

  /**
   * Giftcard
   */
  giftcard: {
    recipientInformation: 'Modtagers information',
    optional: 'Valgfri',
    defaultPaymentSuccessText:
      'Mange tak fordi du har købt et gavekort til en unik bæredygtig oplevelse hos GoBoat.',
  },

  /**
   * Membership
   */
  membership: {
    membership: 'medlemskab',
    yourMembership: 'Dit medlemskab',
    defaultPaymentSuccessText: 'Tak for din tilmelding til GoBoat Plus',
  },

  /**
   * Sauna
   */
  sauna: {
    duration: 'Antal Timmar',
    dateAndTime: 'Datum & Tidpunkt',
    selectDateToViewTimeslots: 'Välj datum för att få tillgängliga tider',
    defaultPaymentSuccessText: `Din booking er gennemført. Vi ser frem til at se dig.

Husk at ankomme mindst 15 min. før din booking begynder.`,
    boat: 'person',
    boats: 'personer',
    numberOfUnits: 'Antal personer',
    personsPerUnit: 'Max 8 personer',
    durationHeading: 'Antal & längd',
  },

  /**
   * Customer
   */
  customer: {
    firstname: 'Förnamn',
    lastname: 'Efternamn',
    phone: 'Telefonnummer',
    email: 'E-post',
    birthDate: 'Fødselsdato',
    birthDatePattern: '(ÅÅÅÅ-MM-DD)',
    zip: 'Postnummer',
    province: 'Län',
    streetAddress: 'Adress',
    city: 'Stad',
    pin: 'Italian Fiscal Code',
  },

  /**
   * Recipient
   */
  recipient: {
    name: 'Namn',
  },

  /**
   * Price
   */
  price: {
    price: 'Pris',
    allIncluded: 'Pris allt ingår',
    paid: 'Betald',
    toBePaid: 'För betalning',
    toBeRefunded: 'För refundering',
    confirm: 'Bekräfta',
  },

  /**
   * Lookup
   */
  lookup: {
    noBookingTitle: 'Hmm, vi hittar inte bokningen ...',
    noBookingContent: 'Vi verkar inte ha någon bokning i systemet med ID:',
    noRequestTitle: 'Letar du efter din bokning?',
    noRequestContent: 'Det ser inte ut som att du sökte efter någon bokning.',
    giefId: 'Vänligen ange boknings-ID från den bekräftelsemail som du fått från oss.',
    getBooking: 'Hämta bokning',
  },

  /**
   * Error
   */
  error: {
    NoShopId: 'Shoppens ID är inte giltigt.',
    NoBoatCount: 'Bokningen har inga båtar.',
    NoAvailableTimeslots: 'Inga tillgängliga tidsluckor.',
    SlotNoLongerAvailable:
      'Den valda avgångstiden är inte längre tillgänglig, välj en annan tid.',
    BookingNotCreated:
      'Ett fel uppstod. Bokningen har inte skapats. Uppdatera sidan och försök igen.',
    SelectStartTime: 'Välj en starttid för din bokning.',
    WeekdayNotValid: 'Den valda veckodagen är inte kvalificerad för denna kupongen.',
    VoucherAlreadyAdded: 'En kupong har redan lagts till i denna bokning.',
    VoucherNotValidForTimeslot: 'Kupongen är inte giltig den valda avgångstiden.',
    PercentVoucherAlreadyUsed:
      'En procent-baserat kupong har redan lagts till i denna bokning.',
    VoucherDepleted: 'Kupongen har inga giltiga användningar kvar på den',
    VoucherLocationInvalid: 'Kupongen är inte giltig för den valda platsen.',
    VoucherWeekdayInvalid: 'Denna kupong är inte giltig för den valda veckodagen.',
    VoucherDateInvalid: 'Denna kupong är inte giltig för det valda datumet.',
    VoucherValidForInvalid: 'Kupongen är inte giltig.',
    VoucherInvalid: 'Kupongen är inte giltig.',
    InvalidVoucherCombination: 'Kupongerna kan inte användas tillsammans.',
    VoucherAlreadyApplied: 'Kupongen är redan tillagd.',
    NotValidPayment:
      'Betalningsbeloppet är inte giltigt - transaktionen har inte genomförts.',
    ShopNotOpen: 'Butiken är inte öppen.',
    ShopNoLongerOpen: 'Butiken har inga fler giltiga öppettider på det valda datumet.',
    ShopHasNoPriceModels: 'Butiken har inga prismodeller.',
    NoShopsFound: 'Inga platser hittades',
    UnknownError: 'Ett okänt fel har inträffat. Vänligen ladda om sidan och försök igen.',
    PriceMismatchError:
      'Priset för den aktuella tidpunkten har uppdaterats. Vänligen ladda om sidan och försök igen.',
    NotAvailable: 'Ej tillgänglig',
    DepartureTimesNotAvailable:
      'Dessa tider är inte tillgängliga med den valda bokningslängden:',
    NotAvailableForOnlineBooking: 'Tider ej tillgängliga online',
    PersonalDetailsInvalid: 'Personuppgifterna är ogiltiga.',
    Alcoholerror: 'Mängden alkohol överskrider den tillåtna gränsen.',
    WarrantyWasRemoved1:
      'Avbeställningsgarantin togs bort eftersom din nuvarande avgångstid är mindre än',
    WarrantyWasRemoved2: 'timmar från nu.',
    WarrantyCannotBeAdded1:
      'Avbeställningsgarantin kan inte läggas till om din avgångstid är mindre än',
    WarrantyCannotBeAdded2: 'timmar från nu.',
    WarrantyMissingDateAndTime:
      'Välj ett datum och en utseglingsperiod innan du lägger till avbokningsgarantin.',
    WarrantyInvalidDateAndTime:
      'Avbeställningsgarantin kan inte läggas till det valda datumet och utresetiden.',
  },

  /**
   * FAQ
   */
  faq: {
    title: 'Så här köper du ditt presentkort',
    p1: 'En unik kod och presentkortet (som .pdf) skickas till din e-post med instruktioner om hur du använder det. Presentkortet är giltigt i',
    p2: 'månader från inköpsdatumet.',
    campaign:
      'En unik kod och presentkortet (som .pdf) skickas till din e-post med instruktioner om hur du använder det. Presentkortets giltighetstid framgår av presentkortet.',
    show: 'Visa FAQ',
    hide: 'Dölja FAQ',
  },

  factBox: {
    close: 'Stäng',
    seeMore: 'Visa mer',
  },

  /**
   * Meta
   */
  meta: {
    description:
      'Lej en båd hos GoBoat og få en unik oplevelse på vandet. Der er plads til op til 8 personer i bådene, og det kræver hverken bevis eller erfaring at styre båden.',
  },

  faqList: [
    'Välj antalet timmar för presentkortet',
    'Ange din e-post (din e-post, inte den person du vill ge presentkortet till)',
    'Ange dina betalningsuppgifter och bekräfta',
  ],

  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Väder',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Ändrade planer',
    [CustomerFacingCancellationReason.SICKNESS]: 'Sjukdom',
    [CustomerFacingCancellationReason.OTHER]: 'Annat',
  },
};
