import { keyframes } from 'styled-components/macro';

export const keyFrameFade = keyframes`
  0% {
    filter: blur(100px);
  }
  100% {
    filter: blur(0px);
  }
`;
