import localizations from '../localizations';

export const getLang = (paramsLang) => {
  switch (paramsLang) {
    case 'da':
    case 'dk':
    case 'den':
    case 'dansk':
    case 'danish': {
      return 'da';
    }
    case 'de':
    case 'deu':
    case 'ger':
    case 'deutsch':
    case 'german': {
      return 'de';
    }
    case 'sv':
    case 'se':
    case 'sve':
    case 'svenska':
    case 'swedish': {
      return 'sv';
    }
    case 'es':
    case 'sp':
    case 'esp':
    case 'español':
    case 'spanish': {
      return 'es';
    }
    case 'italiano':
    case 'italian':
    case 'ita':
    case 'it': {
      return 'it';
    }
    case 'français':
    case 'french':
    case 'fra':
    case 'fr': {
      return 'fr';
    }
    default: {
      return 'en';
    }
  }
};

export const setLanguage = (setLocalization, lang) => {
  const acceptedLangs = [];
  for (const accept in localizations) {
    acceptedLangs.push(accept);
  }
  if (lang && acceptedLangs.indexOf(lang) > 0) {
    return setLocalization(localizations[lang]);
  }
  return setLocalization(localizations['en']);
};

export const getLocalizedShopSettings = (text, lang) => {
  if (!text) return undefined;

  if (typeof text === 'string') return text;

  if (typeof text === 'object' && text[lang]) return text[lang];
};

export const translateErrorShort = (errorShort, localization) => {
  if (localization.error[errorShort]) return localization.error[errorShort];
  return errorShort;
};
