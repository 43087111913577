import styled from 'styled-components/macro';
import ModalDOM from 'styled-react-modal';
import { space, flexbox, layout, color, position, border } from 'styled-system';
import { keyFrameFade } from '../Style/Keyframes';

const defaultBoxShadow = '0 4px 6px rgba(50, 50, 93, 0.15), 0 1px 3px rgba(0, 0, 0, 0.1)';

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  padding-top: 117px;

  max-height: 100vh;
  overflow-y: scroll;

  @media screen and (max-width: 767px) {
    padding-top: 71px;
  }
`;

export const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 32px 51px;
  background-color: ${(props) => props.theme.colors.modals.backgroundColor};
  border-radius: 8px;
  box-shadow: ${(props) => (props.theme.shadows ? defaultBoxShadow : 'none')};
  ${space}
  ${flexbox}

  @media screen and (max-width: 767px) {
    padding: 20px 20px 51px;
  }
`;

export const ModalContainer = styled(ModalDOM)`
  animation: ${keyFrameFade} 5s ease-in-out;
`;

export const Row = styled.div`
  display: flex;
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  @media screen and (max-width: 767px) {
    flex-direction: ${(props) => (props.layout ? `column` : `row`)};
    width: 100%;
    align-items: ${(props) => (props.contentAtTop ? 'center' : undefined)};
  }
`;

export const Box = styled.div`
  display: flex;
  min-height: 35px;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.error
      ? `0px 0px 0px 2px ${props.theme.errorColor}`
      : !props.theme.shadows
      ? 'none'
      : defaultBoxShadow};
  transition: all 0.2s;
  flex: 0 1 auto;
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border};
  @media screen and (max-width: 767px) {
    flex: 1 1 calc(50% - 16px);
  }
`;
