import { parse, stringify } from 'query-string';
import { getLang } from './locale';

const getParams = () => {
  // Get the part of the URL starting with a #
  const hash = window.location.hash;

  // If no params, return empty string
  if (!hash.includes('?')) return '';

  // Return the query param string
  const startOfParams = hash.indexOf('?');
  const paramString = hash.substring(startOfParams);

  // Check "lang" param and rewrite old -> new
  const params = parse(paramString);
  if (params.lang) params.lang = getLang(params.lang);

  const stringifiedParams = `?${stringify(params)}`;
  return stringifiedParams;
};

const getDynamicSegment = (url: string) => {
  // Check if there is two slashes
  const regex = RegExp('/.*/');
  const twoSlashes = regex.test(url);

  if (twoSlashes) {
    // Take everything after the seconded slash
    const indexOfSecondSlash = url.split('/', 2).join('/').length + 1;
    const stringAfterSecondSlash = url.substring(indexOfSecondSlash);

    let endOfDynamicSegment = stringAfterSecondSlash.length;

    // If there is a ?, trim off everything after the ?, including the ?
    if (stringAfterSecondSlash.includes('?'))
      endOfDynamicSegment = stringAfterSecondSlash.indexOf('?');

    const dynamicSegment = stringAfterSecondSlash.substring(0, endOfDynamicSegment);

    // Return the rest
    return dynamicSegment;
  }
};

interface Redirect {
  from: string;
  to: string;
  dynamic?: boolean;
}

const performLegacyRedirect = (legacyRedirects: Redirect[]) => {
  if (window.location.hash && window.location.replace) {
    // Loop over the routes to be redirected
    for (const { from, to, dynamic = null } of legacyRedirects) {
      // If the URL matches a route to be redirected
      if (window.location.hash.includes(from)) {
        const paramString = getParams();

        // If the URL has a dynamic segment and it's in the params
        if (dynamic) {
          // Get the dynimic segment
          const dynamicSegment = getDynamicSegment(window.location.hash);

          // If there is a dynimic segment, create the new route and navigate to it
          if (dynamicSegment) {
            const newRoute = `${to}/${dynamicSegment}${paramString}`;
            return window.location.replace(newRoute);
          }
        }

        // If the URL doesnt have a dynamic segment
        // Create the new route and navigate to it
        const newRoute = `${to}${paramString}`;
        return window.location.replace(newRoute);
      }
    }
  }
};

export default performLegacyRedirect;
